/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { UtilitiesService } from '../utilities.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom, map } from 'rxjs';
import { CacheService } from 'ionic-cache';
import { environment } from 'src/environments/environment';

const COINGECKO_USER_AGENT = 'CoinGecko/2.26.1.394 CFNetwork/1.0 Darwin/23.2.0 (iPhone/13_mini iOS/17.2.1)';

// Cache Settings
const groupKey = 'coins';
const ttl = 600; // 10 minutes

@Injectable({
  providedIn: 'root'
})
export class CoingeckoService {

  currencies = [];
  svgChartColorGreen = '#009E73';
  svgChartColorRed = '#EA3943';
  fiat = 'eur';

  constructor(
    private utilities: UtilitiesService,
    private domSanitizer: DomSanitizer,
    private cacheService: CacheService,
    private httpClient: HttpClient) { }

  async getTicker(options?: TickerOptions): Promise<any[]> {
    try {
      const request = this.httpClient.get(environment.coingecko.apiUrl + '/coins/markets'
        + '?vs_currency=' + (options?.vsCurrency || 'eur')
        + '&order=' + (options?.order || 'market_cap_desc')
        + '&per_page=' + (options?.perPage || '20')
        + '&page=' + (options?.page || '1')
        + '&sparkline=' + (options?.sparkline || 'true')
        + '&price_change_percentage=' + (options?.priceHistory ? '24h,7d,30d,1y,ytd,max' : '')
        + (options?.ids?.length ? '&ids=' + options.ids.join() : ''));


      if (options?.forceRefresh) {
        await this.cacheService.clearGroup(groupKey);
      }

      const response = this.cacheService.loadFromObservable(JSON.stringify(options), request, groupKey, ttl);

      return lastValueFrom(response);
    } catch (e) {
      console.error(e);
      return [];
    }

  }

  async getExchanges(quote: string) {
    try {
      const exchanges = await this.utilities.httpRequest(environment.coingecko.apiUrl + '/exchanges');
      const bitcoinPrice = await this.getCoinPriceById('bitcoin', quote);
      for (const exchange of exchanges) {
        exchange.id_alt = Number(exchange.image.split('/')[5]);
        exchange.trade_volume_24h = exchange.trade_volume_24h_btc * bitcoinPrice.bitcoin[quote.toLocaleLowerCase()];
      }
      console.log(exchanges);
      return exchanges;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  async getCoinPriceById(coinId: string, quote: string = 'USD') {
    try {
      return await this.utilities.httpRequest(environment.coingecko.apiUrl + `/simple/price?ids=${coinId}&vs_currencies=${quote}`);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getGlobalStats(): Promise<any> {
    try {
      const request = this.httpClient.get(environment.coingecko.apiUrl + '/global');
      const response = this.cacheService.loadFromObservable('global', request, groupKey, ttl);

      return lastValueFrom(response);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  getCoinChart(coinId: string, days: string | number, fiat = 'eur') {
    try {
      // eslint-disable-next-line max-len
      const request = this.httpClient.get(environment.coingecko.apiUrl + `/coins/${coinId}/market_chart?vs_currency=${fiat}&days=${days}`);
      const response = this.cacheService.loadFromObservable(`coin-chart-${coinId}-${fiat}-${days}`, request, groupKey, ttl);

      return lastValueFrom(response);
    } catch (error) {
      console.error(error);
      return null;
    }

  }

  async getGlobalCharts(days: string | number, fiat = 'eur') {
    try {
      // eslint-disable-next-line max-len
      const request = this.httpClient.get(`https://www.coingecko.com/market_cap/total_charts_data?vs_currency=${fiat}&duration=${days}`);
      const response = this.cacheService.loadFromObservable(`global-chat-${fiat}-${days}`, request, groupKey, ttl);

      const data = await lastValueFrom(response);
      return {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        market_caps: data.stats, // rename "stats" to "market_caps"
        // eslint-disable-next-line @typescript-eslint/naming-convention
        total_volumes: data.total_volumes,
      };
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  getSearchIndex() {
    // eslint-disable-next-line max-len
    return this.httpClient.get(`/assets/json/coin-index.json`).toPromise() as any;
  }

  async getSearchResults(query: string, fiat = 'eur') {
    if (query.length < 3) {
      return [];
    }

    const ONE_WEEK = 60 * 60 * 24 * 7;
    const request = this.httpClient.get(environment.coingecko.apiUrl + `/search?query=${query}`);
    const response = this.cacheService.loadFromObservable(`search-${query}`, request, groupKey, ONE_WEEK);
    const results = await lastValueFrom(response);

    return results.coins;
  }

  async getCoinPrice(coinId: string, vsCurrencies: string): Promise<SimplePrice> {
    try {
      // eslint-disable-next-line max-len
      const request = this.httpClient.get(environment.coingecko.apiUrl + `/simple/price/?ids=${coinId}&vs_currencies=${vsCurrencies}`)
        .pipe(map(res => res[coinId]));
      const response = this.cacheService.loadFromObservable(`coin-price-${coinId}-${vsCurrencies}`, request, groupKey, ttl);
      return lastValueFrom(response);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  getHistoricalCoinPrice(coinId: string, vsCurrencies: string, date: string): Promise<number> {
    try {
      const ONE_YEAR = 60 * 60 * 24 * 365;
      const request = this.httpClient.get(environment.coingecko.apiUrl + `/coins/${coinId}/history?date=${date}`)
      .pipe(map((res: any) => res?.market_data?.current_price?.[vsCurrencies?.toLowerCase()]));
      // eslint-disable-next-line max-len
      const response = this.cacheService.loadFromObservable(`historical-coin-${coinId}-${vsCurrencies}-${date}`, request, groupKey, ONE_YEAR);
      return lastValueFrom(response);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  // async getGlobalDeFiStats(quote?: string) {

  //   const resp: any = await this.utilities.httpRequest('https://api.coingecko.com/api/v3/global/decentralized_finance_defi',
  // {
  //   enableCors: true
  // });
  //   if (quote && quote !== 'USD') {
  //     const fiatCurrency = this.fiatService.getFiatCurrency(quote);
  //     const exchangeRate: any = await this.coinpaprika.priceConverter('usd-us-dollars', fiatCurrency.id, 1);

  //     resp.data.defi_market_cap = Number(resp.data.defi_market_cap) * exchangeRate.price;
  //     resp.data.eth_market_cap = Number(resp.data.eth_market_cap) * exchangeRate.price;
  //     resp.data.trading_volume_24h = Number(resp.data.trading_volume_24h) * exchangeRate.price;
  //   }

  //   return resp;
  // }

  async getSvgChart(coinId: number) {
    try {
      let svg: string = await this.utilities.httpRequest(`https://www.coingecko.com/coins/${coinId}/sparkline`,
        { headers: { accept: 'image/svg+xml' }, responseType: 'text', enableCors: true });

      const chartColor = svg.includes('#57bd0f') ? 'green' : 'red';

      // replace colors of line
      svg = svg.replace('#57bd0f', this.svgChartColorGreen);
      svg = svg.replace('#ed5565', this.svgChartColorRed);

      // fill chart
      // const polylinePoints = svg.match(new RegExp('points\=\"([^"]*)\"', 'ig'));
      // let polygon = `<polygon ${polylinePoints[0]} fill="${chartColor == 'green' ? this.svgChartColorGreen : this.svgChartColorRed}" />`;
      // polygon = polygon.replace(`<polygon points="`, `<polygon points="0, 50 `);
      // polygon = polygon.replace(`" fill="`, ` 135, 50" fill="`);

      // svg = svg.replace('<svg', '<svg preserveAspectRatio="none"');
      // svg = svg.replace(`</svg>`, `${polygon}\n</svg>`);

      return this.domSanitizer.bypassSecurityTrustHtml(svg);
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  /**
   * Get fiat currency meta by short from
   *
   * @param value 'eur' | 'usd' | 'chf' | 'btc' | 'eth'
   * @returns FiatCurrency
   */
  getFiatCurrency(value: string): FiatCurrency {
    const fiatCurrencies = this.getFiatCurrencies();
    return fiatCurrencies.find(fiatCurrency => fiatCurrency.value.toLocaleLowerCase() === value.toLocaleLowerCase());
  }

  getFiatCurrencies() {
    return [
      {
        text: 'Euro (€)',
        value: 'eur',
        sign: '€',
        icon: 'https://s2.coinmarketcap.com/static/cloud/img/fiat-flags/EUR.svg'
      },
      {
        text: 'US Dollar ($)',
        value: 'usd',
        sign: '$',
        icon: 'https://s2.coinmarketcap.com/static/cloud/img/fiat-flags/USD.svg'
      },
      {
        text: 'Schweizer Franken (Fr.)',
        value: 'chf',
        sign: 'Fr.',
        icon: 'https://s2.coinmarketcap.com/static/cloud/img/fiat-flags/CHF.svg'
      },
      {
        text: 'Bitcoin (₿)',
        value: 'btc',
        sign: '₿',
        icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1.png'
      },
      {
        text: 'Ethereum (Ξ)',
        value: 'eth',
        sign: 'Ξ',
        icon: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png'
      },

    ];
  }
}

export interface TickerOptions {
  vsCurrency?: string;
  order?: string;
  forceRefresh?: boolean;
  perPage?: number;
  page?: number;
  sparkline?: boolean;
  priceHistory?: boolean;
  ids?: string[];
}

export interface FiatCurrency {
  text: string;
  value: string;
  sign: string;
  icon: string;
}

export interface SimplePrice {
  eur: number;
  usd: number;
  chf: number;
  btc: number;
  eth: number;
}

export interface Coin {
  id: string;
  symbol: string;
  name: string;
  image: string;
  current_price: number;
  market_cap: number;
  market_cap_rank: number;
  fully_diluted_valuation: number;
  total_volume: number;
  high_24h: number;
  low_24h: number;
  price_change_24h: number;
  price_change_percentage_24h: number;
  market_cap_change_24h: number;
  market_cap_change_percentage_24h: number;
  circulating_supply: number;
  total_supply: number;
  max_supply: number;
  ath: number;
  ath_change_percentage: number;
  ath_date: string;
  atl: number;
  atl_change_percentage: number;
  atl_date: string;
  roi?: any;
  last_updated: string;
  sparkline_in_7d?: {
    price: number[];
  };
}
